// @flow

import { useCallback, useEffect, useState } from 'react';

import axios from '../api/axios';

const useSetupInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [setupInfo, setSetupInfo] = useState(null);

  const fetchSetupInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const { data } = await axios.get('/api/v1/setup');

      setSetupInfo(data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSetupInfo();
  }, [fetchSetupInfo]);

  return {
    isLoading,
    error,
    setupInfo,
    fetchSetupInfo,
  };
};

export default useSetupInfo;
