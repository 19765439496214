import './index.less';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ButtonArrowRed from '../../../static/svg/button-arrow-red.svg';
import ButtonArrow from '../../../static/svg/button-arrow.svg';

const AppButton = (props) => {
  const [hover, setHover] = useState(false);
  const {
    disabled = false,
    type = 'button',
    size = 'lg',
    width = 'inline',
    color = 'red',
    darkMode = false,
    transform = 'none',
    children,
  } = props;

  const btnClasses = classNames({
    md: size === 'md',
    lg: size === 'lg',
    inline: width === 'inline',
    full: width === 'full',
    red: color === 'red',
    grey: color === 'grey',
    nobg: color === 'nobg',
    outline: color === 'outline',
    link: color === 'link',
    redlink: color === 'redlink',
  });

  return (
    <button
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`btn ${btnClasses} ${disabled && 'btn-disabled'}`}
      onClick={() => {
        if (!disabled && props.onClick) {
          props.onClick();
        }
      }}
      type={type}
      {...props}
      style={{ textTransform: transform }}
    >
      <div className={` ${hover && 'btn-hover'}`}>
        {children}
        {color === 'link' && <img src={darkMode ? ButtonArrowRed : hover ? ButtonArrowRed : ButtonArrow} />}
      </div>
    </button>
  );
};

AppButton.propTypes = {
  type: PropTypes.string, // button, submit
  size: PropTypes.string, // md, lg
  disabled: PropTypes.bool,
  darkMode: PropTypes.bool,
  width: PropTypes.string, // inline, full
  color: PropTypes.string, // red, grey
};

export default AppButton;
