import './index.less';

import { Divider } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Title = ({
  isTagline = false,
  isTitleImage = false,
  isCenter = false,
  title,
  divider = false,
  tagline,
  description,
  titleFontSize,
  titleClassName,
  paragraphStyle,
  isUppercase = true,
  ...props
}) => (
  <div
    className={`${isTagline ? 'title title-w-tagline' : 'title'} ${isCenter && 'center'} ${
      titleClassName && titleClassName
    }`}
    {...props}
  >
    {isTitleImage ? (
      <img src={`${title}`} alt="" />
    ) : (
      <h1
        style={{ textTransform: isUppercase ? 'uppercase' : 'inherit' }}
        dangerouslySetInnerHTML={{
          __html: (isTagline && isCenter ? ' ' : '') + title + (isTagline ? ' ' : ''),
        }}
      />
    )}
    {divider && <Divider />}
    {tagline && (
      <h2
        dangerouslySetInnerHTML={{
          __html: tagline,
        }}
        style={{ fontSize: titleFontSize }}
      />
    )}
    {description && (
      <div style={paragraphStyle}>
        <p>{description}</p>
      </div>
    )}
  </div>
);

Title.propTypes = {
  isTagline: PropTypes.string,
  title: PropTypes.string.isRequired,
  isTitleImage: PropTypes.bool,
  isUppercase: PropTypes.bool,
  isCenter: PropTypes.bool,
  divider: PropTypes.bool,
  tagline: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.string,
  paragraphStyle: PropTypes.object,
  titleFontSize: PropTypes.number,
};

export default Title;
