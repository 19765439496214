import './index.less';

import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Disclaimer from '../Disclaimer';
import AppFooter from '../Footer';
import FullMenu from '../FullMenu';
import Navbar from '../Navbar/navbar';

const { Content, Footer, Header } = Layout;

class AppLayout extends Component {
  state = {
    showMenu: false,
    showNav: true,
    scrollPos: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.scrollToggleNavbar, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollToggleNavbar, true);
  }

  componentDidUpdate() {
    document && this.state.showMenu
      ? document.body.classList.add('scrollfix')
      : document.body.classList.remove('scrollfix');
  }

  lockScroll = (e) => {
    e.preventDefault();
  };

  toggleFullMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  handleMouseMove = (e) => {
    if (this.state.scrollPos < -400) {
      if (e.clientY < 400) {
        this.setState({ showNav: true });
      } else {
        this.setState({ showNav: false });
      }
    }
  };

  scrollToggleNavbar = () => {
    const { scrollPos } = this.state;
    if (window.scrollY > 100) {
      this.setState({
        scrollPos: document.body.getBoundingClientRect().top,
        showNav: document.body.getBoundingClientRect().top > scrollPos,
      });
    }
  };

  render() {
    const {
      children,
      fixedHeader = false,
      navShadowShown,
      navInvert,
      footer = true,
      disclaimer = false,
      noTopPadding,
      banner,
    } = this.props;
    const { showMenu, showNav, scrollPos } = this.state;

    const showNavbar = fixedHeader ? 'active' : showMenu ? 'active' : showNav ? 'active' : 'hidden';
    let navClass = '';

    if ((navInvert && scrollPos > -400) || showMenu) {
      navClass = 'inverse';
    }

    if (!navInvert && scrollPos < -400 && !showMenu) {
      navClass = '';
    }

    return (
      <Layout onMouseMove={this.handleMouseMove} hasSider={false}>
        <Header className={`navheader ${navClass} ${showNavbar}`}>
          <Navbar menuShown={showMenu} shadowShown={navShadowShown} toggleFullMenu={this.toggleFullMenu} />
        </Header>

        {showMenu ? <FullMenu toggleFullMenu={this.toggleFullMenu} /> : null}

        <Content className={noTopPadding ? 'no-top-padding' : null}>{children}</Content>

        {footer && (
          <Footer>
            <AppFooter />
          </Footer>
        )}
        {disclaimer && <Disclaimer />}

        {banner}
      </Layout>
    );
  }
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navInvert: PropTypes.bool,
  navShadowShown: PropTypes.bool,
  t: PropTypes.func, // not in use yet
  banner: PropTypes.node,
};

export default AppLayout;
