import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@tvai/gatsby-plugin-i18next';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

import './index.less';

const LegalLinks = (props) => {
  const { t } = useTranslation();

  return (
    <div className="legal-links">
      <Link to="/terms/">{t('TermsOfUse')}</Link>
      <Divider type="vertical"></Divider>
      <Link to="/privacy/">{t('PrivacyPolicy')}</Link>
      <Divider type="vertical"></Divider>
      <Link to="/cookies/">{t('CookiePreferences')}</Link>
    </div>
  );
};

LegalLinks.propTypes = {
  t: PropTypes.func
};

export default LegalLinks;
