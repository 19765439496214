// @flow
import './index.less';

import { Link } from '@tvai/gatsby-plugin-i18next';

import { Col, Divider, Row } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import i18n from 'i18next';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import logoWhiteWide from '../../images/footer-logo.png';
import Breakpoint from '../../utils/breakpoint';
import AppStoreBadges from '../AppStoreBadges';
import CountryPicker from '../CountryPicker';
import LegalLinks from '../LegalLinks';
import SocialMediaIconLinks from '../SocialMediaIconLinks';

const Footer = () => {
  const { allRitualLocations } = useStaticQuery(
    graphql`
      query {
        allRitualLocations(filter: { CountryCode: { ne: "ZA" } }) {
          edges {
            node {
              id
              CountryCode
              Country
            }
          }
        }
      }
    `
  );

  const { t } = useTranslation();

  return (
    <div className="container">
      <Breakpoint max="lg">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={20} lg={8}>
            <SocialMediaIconLinks />
          </Col>
        </Row>
      </Breakpoint>
      <Row justify="center" align="middle">
        <Col xs={24} sm={8} md={8} lg={8}>
          <div className="sitemap-links">
            <Link to="/">{t('Home')}</Link>
            <Link to="/faq/">{t('FooterMenu_FAQ')}</Link>
            <a href="https://www.ritualgym.com/">ritualgym.com</a>
          </div>
        </Col>
        <Col xs={20} sm={20} md={20} lg={8}>
          <Link className="logo" to="/">
            <img src={logoWhiteWide} alt="Ritual Logo" />
          </Link>
        </Col>
        <Breakpoint min="lg">
          <Col xs={8} sm={8} md={8} lg={8}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SocialMediaIconLinks />
            </div>
          </Col>
        </Breakpoint>
      </Row>

      <Row align="middle">
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 18, order: 2 }}
          md={{ span: 18, order: 2 }}
          lg={{ span: 8, order: 1 }}
        >
          <span className="copyright">Copyright &copy; 2022 Ritual Gym. {`${t('AllRightsReserved')}`}</span>
        </Col>

        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 8, order: 2 }}
        >
          <div className="legal-links-wrapper">
            <LegalLinks />
          </div>
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 6, order: 2 }}
          md={{ span: 6, order: 2 }}
          lg={{ span: 8, order: 3 }}
        >
          <div className="country-picker-wrapper" />
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
