import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.GATSBY_RITUAL_REST_URL,
  headers: {
    'content-type': 'application/json',
    'access-control-allow-origin': '*',
    Authorization: 'Token deduRcRBNuOZ2ACfxl5',
  },
});

export default instance;
