// @flow
import './index.less';

import { CheckOutlined } from '@ant-design/icons';

import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import useSetupInfo from '../../hooks/useSetupInfo';
import { checkFlagLarge, checkFlagName, inCountry } from '../../utils/flag';
import AppButton from '../Button';

const CountryPicker = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['countryCode']);

  const { setupInfo } = useSetupInfo();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const inBranchCountry = useMemo(() => {
    if (cookies.countryCode) {
      return inCountry(cookies.countryCode.toLowerCase());
    }
    return false;
  }, [cookies.countryCode]);

  useEffect(() => {
    if (setupInfo) {
      setLoading(false);
      if (inCountry(setupInfo.Country_Code.toLowerCase()) && !cookies.countryCode) {
        setCookie('countryCode', setupInfo.Country_Code.toLowerCase(), { path: '/' });
        setSelectedCountry(setupInfo.Country_Code.toLowerCase());
      }
    }
  }, [setupInfo]);

  const url = typeof window !== 'undefined' ? window.location.pathname : '';

  const activeCountry = useMemo(() => {
    if (cookies.countryCode && setupInfo?.Countries) {
      const country = setupInfo.Countries.find((c) => c.isocode.toLowerCase() === cookies.countryCode.toLowerCase());

      return country;
    }
    return null;
  }, [setupInfo, cookies.countryCode]);

  if (inBranchCountry !== false) {
    if (activeCountry) {
      return (
        <div
          className="country-picker"
          onClick={() => {
            removeCookie('countryCode', { path: '/' });
          }}
        >
          {activeCountry.name}
          <img
            src={checkFlagLarge(activeCountry.isocode.toLowerCase())}
            alt={activeCountry.name}
            style={{ width: '40px' }}
          />
        </div>
      );
    }
  }

  if (loading) {
    return null;
  }

  if (url.includes('/franchise')) {
    return null;
  }

  return (
    <div className="overlay">
      <div className="innerContent">
        {!loading && (
          <>
            <Row justify="center" style={{ textAlign: 'center' }}>
              <Col xs={24} sm={24} md={18} lg={18}>
                <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>{t('common_chooseyourcountry')}</h1>
                {setupInfo?.Countries.filter((country) => country.id !== 2 && country.id !== 9).map((country) => (
                  <div
                    onClick={() => {
                      setSelectedCountry(country.isocode.toLowerCase());
                    }}
                    className="countryItem"
                  >
                    <div className="countryItem_name">
                      <img
                        src={checkFlagLarge(country.isocode.toLowerCase())}
                        alt={country.name}
                        style={{ width: '40px' }}
                      />
                      {checkFlagName(country.isocode.toLowerCase())}
                    </div>
                    <div
                      className={`countryItem_box ${
                        country.isocode.toLowerCase() === selectedCountry && 'countryItem_box_selected'
                      }`}
                    >
                      {country.isocode.toLowerCase() === selectedCountry && <CheckOutlined />}
                    </div>
                  </div>
                ))}
                <div style={{ marginTop: '40px' }}>
                  <AppButton
                    onClick={() => {
                      setCookie('countryCode', selectedCountry, { path: '/' });
                    }}
                    type="button"
                    disabled={!inCountry(selectedCountry)}
                    transform="lowercase"
                    color="reds"
                  >
                    <span>{t('StartYourRitual')}</span>
                  </AppButton>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

function propsAreEqual(prev, next) {
  return prev.data === next.data;
}

export default React.memo(CountryPicker, propsAreEqual);
