import './index.less';

import { Link } from '@tvai/gatsby-plugin-i18next';

import { Col, Divider, Row } from 'antd';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import useSetupInfo from '../../hooks/useSetupInfo';
import Breakpoint from '../../utils/breakpoint';
import { checkFlagLarge, checkFlagName, inCountry } from '../../utils/flag';
import LegalLinks from '../LegalLinks';
import SocialMediaIconLinks from '../SocialMediaIconLinks';

const FullMenu = (props) => {
  const { t } = useTranslation();
  const { toggleFullMenu } = props;

  const lang = i18n.language.toLowerCase();

  return (
    <div className="fullmenu">
      <div className="container">
        <div style={{ flex: 1 }}>
          <Row type="flex" justify="space-between">
            <Col span={24}>
              <ul className="navmenu">
                <li>
                  <Link to="/" onClick={toggleFullMenu}>
                    {t('Home')}
                  </Link>
                </li>
                <li>
                  <Link to="/faq">{t('FooterMenu_FAQ')}</Link>
                </li>
                <li>
                  <a href="https://www.ritualgym.com/">ritualgym.com</a>
                </li>
              </ul>
            </Col>
          </Row>
          <Breakpoint max="lg">
            <Row type="flex">
              <Col span={24}>
                <ul className="ritual-links" />
              </Col>
            </Row>
          </Breakpoint>
        </div>
        <div>
          <Row type="flex" justify="space-between">
            <Breakpoint min="lg">
              <Col span={6}>
                <div className="social-links">
                  <SocialMediaIconLinks />
                </div>
              </Col>
              <Col span={18}>
                <ul className="ritual-links" />
              </Col>
            </Breakpoint>

            <Breakpoint max="lg">
              <Row type="flex">
                <Divider className="divider" />
                <Col span={24}>
                  <div className="social-links">
                    <SocialMediaIconLinks />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="legal-links-wrapper">
                    <LegalLinks />
                  </div>
                </Col>
              </Row>
            </Breakpoint>
          </Row>
        </div>
      </div>
    </div>
  );
};

FullMenu.propTypes = {
  t: PropTypes.func,
  toggleFullMenu: PropTypes.func,
};

export default FullMenu;
