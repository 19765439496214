import FlagBRLarge from '../images/flags/br_large.png';
import FlagBR from '../images/flags/br.svg';
import FlagCHLarge from '../images/flags/ch_large.png';
import FlagCH from '../images/flags/ch.svg';
import FlagCNLarge from '../images/flags/cn_large.png';
import FlagCN from '../images/flags/cn.svg';
import FlagESLarge from '../images/flags/es_large.png';
import FlagES from '../images/flags/es.svg';
import FlagSGLarge from '../images/flags/sg_large.png';
import FlagSG from '../images/flags/sg.svg';
import FlagUSLarge from '../images/flags/us_large.png';
import FlagUS from '../images/flags/us.svg';

export const checkFlag = (isoCode) => {
  switch (isoCode) {
    case 'br':
      return FlagBR;
    case 'ch':
      return FlagCH;
    case 'es':
      return FlagES;
    case 'sg':
      return FlagSG;
    case 'cn':
      return FlagCN;
    default:
      return null;
  }
};

export const inCountry = (isoCode) => {
  switch (isoCode) {
    case 'br':
    case 'ch':
    case 'es':
    case 'sg':
    case 'cn':
      return true;
    default:
      return false;
  }
};

export const checkFlagLarge = (isoCode) => {
  switch (isoCode) {
    case 'br':
      return FlagBR;
    case 'ch':
      return FlagCH;
    case 'es':
      return FlagES;
    case 'sg':
      return FlagSG;
    case 'cn':
      return FlagCN;
    default:
      return null;
  }
};

export const checkFlagName = (isoCode) => {
  switch (isoCode) {
    case 'br':
      return 'Brasil';
    case 'cn':
      return 'China';
    case 'es':
      return 'España';
    case 'sg':
      return 'Singapore';
    case 'ch':
      return 'Switzerland';
    default:
      return null;
  }
};
