import './index.less';

import React from 'react';

import FacebookBlackIcon from '../../images/social-facebook-black.png';
import FacebookIcon from '../../images/social-facebook.png';
import InstagramBlackIcon from '../../images/social-instagram-black.png';
import InstagramIcon from '../../images/social-instagram.png';
import SpotifyBlackIcon from '../../images/social-spotify-black.png';
import SpotifyIcon from '../../images/social-spotify.png';
import TiktokBlackIcon from '../../images/social-tiktok-black.png';
import TiktokIcon from '../../images/social-tiktok.png';
import YoutubeBlackIcon from '../../images/social-youtube-black.png';
import YoutubeIcon from '../../images/social-youtube.png';

const SocialMediaIconLinks = ({ dark = false }) => (
  <div className={`social-icons ${dark && 'social-icons-dark'}`}>
    <a href="https://www.instagram.com/ritualgym/" target="_blank" rel="noopener noreferrer">
      <img className="icons" src={dark ? InstagramBlackIcon : InstagramIcon} alt="Spotify Icon" />
    </a>
    <a href="http://facebook.com/RitualGym" target="_blank" rel="noopener noreferrer">
      <img
        className="icons"
        src={dark ? FacebookBlackIcon : FacebookIcon}
        alt="Facebook Icon"
        rel="noopener noreferrer"
      />
    </a>
    <a href="https://www.tiktok.com/@ritualgym" target="_blank" rel="noopener noreferrer">
      <img className="icons" src={dark ? TiktokBlackIcon : TiktokIcon} alt="Tiktok Icon" />
    </a>
    <a href="https://www.youtube.com/channel/UCVvPWaVaIEyabfjCgNP-q-A" target="_blank" rel="noopener noreferrer">
      <img className="icons" src={dark ? YoutubeBlackIcon : YoutubeIcon} alt="Spotify Icon" />
    </a>
    <a href="https://open.spotify.com/user/ritualgym" target="_blank" rel="noopener noreferrer">
      <img className="icons" src={dark ? SpotifyBlackIcon : SpotifyIcon} alt="Spotify Icon" />
    </a>
  </div>
);

export default SocialMediaIconLinks;
