// @flow
import './index.less';

import { Link } from '@tvai/gatsby-plugin-i18next';

import { Col, Row } from 'antd';
import i18n from 'i18next';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from '../BannerShared/Title';

const Disclaimer = () => {
  const { t } = useTranslation();
  const lang = i18n.language.toLowerCase();

  return (
    <div className="disclaimer">
      <div className="container">
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={18} lg={18}>
            <Title title={t('franchise_disclaimer_title')} />
            <p>{t('franchise_disclaimer_body')}</p>{' '}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Disclaimer;
