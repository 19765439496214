import './navbar.less';

import { Link } from '@tvai/gatsby-plugin-i18next';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';

import axios from '../../api/axios';
import Breakpoint from '../../utils/breakpoint';
import AppButton from '../Button';

const Navbar = ({ toggleFullMenu, menuShown, shadowShown, t }) => {
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  return (
    <div className={`navbar-wrapper ${menuShown && 'menuMode'} ${shadowShown && 'shadowMode'}`}>
      <Row type="flex" justify="space-around" align="middle" style={{ width: '100%' }}>
        <Col span={5}>
          <Link to="/" className="logo-row">
            <Breakpoint min="lg">
              <div className="navbar-logo" />
            </Breakpoint>
            <Breakpoint max="lg">
              <div className="navbar-logo navbar-logo-small" />
            </Breakpoint>
          </Link>
        </Col>
        <Col span={19}>
          <div className="menu-row">
            {!menuShown && (
              <>
                <Breakpoint min="lg">
                  <Link to="/">{t('Home')}</Link>
                  <Link to="/faq/">
                    <AppButton type="button" size="md" color="red" width="inline">
                      {t('FooterMenu_FAQ')}
                    </AppButton>
                  </Link>
                </Breakpoint>
              </>
            )}

            <span className="icon-menu" onClick={toggleFullMenu}>
              {menuShown ? (
                // Change to background image instead of img tag
                <div className="icon-close" />
              ) : (
                <div className="icon-hamburger" />
              )}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

Navbar.propTypes = {
  t: PropTypes.func.isRequired,
  toggleFullMenu: PropTypes.func.isRequired,
  menuShown: PropTypes.bool,
  shadowShown: PropTypes.bool,
};

export default withTranslation()(Navbar);
