import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

/** ***
 NOTE: Use <Breakpoint> only for hiding/showing components/jsx.
 Breakpoint takes 2 props, min and max.
 Specifying both results in a ranged css width media query, xxx and yyy.
 Specifying one prop results in a single css width media query.
 ***** */

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const Breakpoint = (props) => {
  const size = useWindowSize();

  const breakpoints = {
    xl: 1200,
    lg: 992,
    md: 769,
    sm: 576,
    xs: 480,
  };

  const getData = (width) => {
    if (props.min && !props.max) {
      if (breakpoints[props.min] <= width) {
        return props.children;
      }
    }
    if (!props.min && props.max) {
      if (breakpoints[props.max] > width) {
        return props.children;
      }
    }
    if (props.min && props.max) {
      if (breakpoints[props.max] >= width && breakpoints[props.min] <= width) {
        return props.children;
      }
    }
  };

  return (
    <>
      <>{getData(size.width)}</>
    </>
  );
};

React.propTypes = {
  min: PropTypes.string,
  test: PropTypes.bool,
  max: PropTypes.string,
  children: PropTypes.object,
};

export default Breakpoint;
